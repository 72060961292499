/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* import quill styles */
@import "~quill/dist/quill.snow.css";

/* import owl date time picker styles */
@import "theme/ng-pick-datetime/assets/style/picker.min";
@import "theme/ng-pick-datetime/assets/style/picker-customizations.scss";

/* import */
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeicons/primeicons.css";

@import "node_modules/@swimlane/ngx-datatable/index.css";
@import "node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

/* Import Swiper styles */

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
/*----------------------------------------------------------------*/
/*custom salonMonster styles. All styles are namespaced with smr- to prevent conflicts with other library css */
/*----------------------------------------------------------------*/

/* from last ap.scss */
h2 {
  font-weight: bold;
}

h3 {
  font-weight: bold;
}

h4 {
  font-weight: bold;
}
/*----------------------------------------------------------------*/
/*custom salonMonster styles. All styles are namespaced with smr- to prevent conflicts with other library css */
/*----------------------------------------------------------------*/

ion-input:not(.ng-touched).ng-invalid {
  border: 1px solid white !important;
}

.ion-accordion {
  .ion-accordion-toggle-icon {
    color: var(--ion-color-dark);
  }
}

ion-accordion-group {
  .no-icon {
    .ion-accordion-toggle-icon {
      display: none;
    }
  }
  ion-accordion {
    .ion-accordion-toggle-icon {
      color: white;
    }
  }
}
.accordion-expanded > .expandable {
  --background: #6d6d6d !important;
}

.smr {
  &-error {
    color: var(--ion-color-danger);
    margin-top: 0;
  }
  &-clickable {
    cursor: pointer;
  }
  &-empty-state {
    height: 100%;
    color: #666;
    text-align: center;
    padding: 50px;
    ion-row {
      height: 100%;
    }
    img {
      width: 200px;
      max-width: 75%;
    }
  }
  &-label-under {
    font-size: 0.8em;
    margin-top: -4px;
    display: block;
    color: #666;
  }
  &-client-selected > ion-item {
    --background: var(--ion-color-medium) !important;
  }
  &-danger {
    color: red;
  }
}

/*----------------------------------------------------------------*/
/* styling built in ionic components */
/*----------------------------------------------------------------*/

/* give inputs a border */
.sc-ion-input-md-h,
ion-select {
  border: 2px solid var(--ion-color-medium);
  border-radius: 2px;
  background: #fafafa;
  padding: 8px 10px 10px 10px;
  margin: 0;
  width: 100%;
  --padding-start: 8px !important;
  margin-top: 8px;
  &.ion-invalid {
    /* make invalid inputs red */
    border: 1px solid var(--ion-color-danger);
    background: var(--ion-color-danger-light);
  }
}

/* stop the whole ion item from changing colour when hovering over or clicking on a select */
ion-item.item-select {
  --background-hover-opacity: 0;
  --background-focussed: transparent;
  --background-focussed-opacity: 0;
  transition: none;
  // TODO: make it not show on click either - still doing it
}

.label-stacked {
  font-weight: bold;
}

/* increase the size of buttons in the ion footer */
ion-footer .sc-ion-buttons-md-s ion-button {
  height: 45px;
  --padding-start: 25px;
  --padding-end: 25px;
}
/********* QUILL EDITOR ************/

.quill-custom-email * .ql-editor {
  height: 175px !important;
}

.ql-editor {
  height: 300px;
}

.ql-divider:after {
  content: "|" !important;
}

.ql-tooltip {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

ion-popover {
  --width: 300px;
}

/***** Button *******************/
em {
  font-weight: bold;
  font-style: normal;
}

/*.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus) .item-inner,
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child {
  border-bottom-color: map-get($colors, primary);
  box-shadow: inset 0 -1px 0 0 map-get($colors, primary);
}*/

form {
  .item-input .label-md,
  .item-select .label-md,
  .item-datetime .label-md,
  ion-label.label-md,
  ion-label.label-md .note-md {
    color: var(--ion-color-medium);
  }
}

.label-md[stacked],
ion-label.label-md .note-md {
  font-size: 1.5rem;
  font-weight: bold;
}

ion-menu {
  ion-content {
    background-color: var(--ion-color-darkgrey) !important;
  }
}

.split-pane-md.split-pane-visible > .split-pane-side {
  min-width: 250px;
  max-width: 250px;
}

.menu-collapsed.split-pane-md.split-pane-visible > .split-pane-side,
.menu-collapsed.split-pane-md.split-pane-visible
  > .split-pane-side
  .menu-inner {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

.menu-collapsed ion-menu {
  .item-md {
    padding-left: 10px;

    .item-inner {
      padding-right: 0;
    }
  }

  .item-md ion-icon[item-left] + .item-inner {
    margin-left: 0;
    transform: translate(-15px, -9px);

    z-index: 1000;
  }

  .item-md [item-right] {
    margin: 0;
  }

  ion-badge {
    padding: 1px 4px;
    font-size: 1.2rem;
  }

  #sm-slide-nav-bar button {
    ion-icon {
      margin-right: 0;
    }

    text-align: center;
    width: 100%;
    background: transparent;
  }
}

#sm-slide-nav {
  &-logged-in-user {
    background-color: var(--ion-color-medium) !important;
    color: var(--ion-color-light-fff);

    .item-inner {
      border: 0px;
    }

    ion-label {
      flex-grow: 14;
    }
  }

  &-bar {
    .item-md {
      background-color: var(--ion-color-darkgrey) !important;

      ion-label.label-md {
        color: var(--ion-color-light-fff);
      }

      ion-icon {
        color: var(--ion-color-light-fff);
      }
    }
  }
}

.center-block {
  display: block;
  margin: auto;
}

.searchbar-md .searchbar-input {
  padding: 6px 6px 6px 55px;
}

button.bar-buttons.bar-buttons-md.bar-button-menutoggle {
  @media (min-width: 768px) {
    display: none;
  }
}

.ion-md-menu {
  &::after {
    content: "MENU";
    display: block;
    margin-top: 5px;
    font-size: 8px;
  }
}

.item-md ion-avatar ion-img,
.item-md ion-avatar img {
  padding: 3px;
}

ion-app.md .scroll-content {
  padding-left: 0;
}

.invisible {
  visibility: hidden !important;
}

button.sm-clickable.bar-buttons.bar-buttons-md.bar-button.bar-button-md.bar-button-default.bar-button-default-md.bar-button-menutoggle.bar-button-menutoggle-md {
  margin-top: -4px;
  height: 35px;
}

.button-md,
.button-inner {
  text-transform: none !important;
}

/*.segment :not(.segment-activated) {
  color: $text-color  !important;
}*/

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }

  .campertunity-nav-sm-down {
    .toolbar-background-md {
      background: none;
    }
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .client-address {
    ion-row {
      display: inline-flex;
      align-items: center;
      margin-right: 0.3rem;
    }

    ion-col {
      white-space: nowrap;
    }
  }
}
.hidden-xl-down {
  display: none !important;
}

button {
  text-transform: none !important;
  &:hover {
    opacity: 0.7;
  }
}

/***** Button *******************/

.popover-content {
  min-width: 300px !important;
}

a {
  text-decoration: none;
}

.service-item:hover {
  --color: #666;
  .service-note {
    color: #666;
  }
}
.service-note {
  font-size: 1rem;
  color: black;
}

.smr-client-selected > ion-item {
  --background: var(--ion-color-medium) !important;
}
.tertiary-button {
  --background: var(--ion-color-tertiary-EFE);
  color: black;
  font-size: 12px;
  font-weight: 600;
  --padding-start: 1.7rem;
  --padding-end: 1.7rem;
  --box-shadow: none;
}
.tab-group {
  width: 100%;
}

.tab-button {
  height: 44px;
  width: 50%;
  background: var(--ion-color-light-eee);
  border-top: 1px solid var(--ion-color-medium);
  border-right: 1px solid var(--ion-color-light-ddd);
  border-bottom: 1px solid var(--ion-color-medium);
  margin: 0 !important;
}

.tab-checked {
  background: var(--ion-color-light-fff);
  border-top: 1px solid var(--ion-color-medium);
  border-right: 1px solid var(--ion-color-light-ddd);
  border-bottom: 0px;
  margin: 0 !important;
}

ion-button {
  text-transform: none;
  --border-radius: 4px;
}

.modal-fullscreen {
  position: absolute;
  top: 0;
  left: 249px;
  --width: 100%;
  --height: 100%;
}

.modal-fullscreen-c {
  position: absolute;
  top: 0;
  left: 3rem;
  --width: 100%;
  --height: 100%;
}

@media (max-width: 990px) {
  .modal-fullscreen {
    left: 0 !important;
    --width: 100% !important;
  }
  .modal-fullscreen-c {
    left: 0 !important;
    --width: 100% !important;
  }
}

@media print {
  .main-left-menu {
    display: none;
  }
}

.p-datepicker {
  border-bottom: 0;
}

.p-datepicker table td {
  padding: 0 !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background: var(--ion-color-light-fff);
}

.p-datepicker table td > span.p-highlight {
  background: none !important;
}

.sm-error {
  color: red;
}

.modal-bg {
  background: rgba(0, 0, 0, 0.4) !important;
}

.selected-recipients > .ionic-selectable-inner > .ionic-selectable-value {
  display: none;
}

.selected-recipients > .ionic-selectable-inner > .ionic-selectable-icon {
  display: none;
}

ion-item {
  --background: var(--ion-color-step-50);
}

.smr-picker-stylist-select-text {
  --padding-end: 20px;
}

.ios ion-input {
  border: solid 2px #cfcfcf;
  background: #f5f5f5;
  .native-input {
    padding-left: 0.5rem;
  }
}

ion-datetime {
  margin: auto;
  background: transparent;
}

.capitalize {
  text-transform: capitalize;
}

.card-element {
  display: block;
  margin: 40px auto;
}
.stripe-charge-buttons {
  width: 80%;
  margin: 50px auto;
}
.charge-buttons {
  width: 45%;
}
#manual-card-checkout .toolbar-background {
  background-color: #333;
}
#manual-card-checkout .toolbar-title {
  color: #fff;
  text-align: center;
}
#manual-card-checkout .bar-buttons button {
  color: white;
}
.card-element-style {
  border: 1px solid #ccc;
  padding: 10px 15px;
}
.card-on-file-text {
  color: #666;
}
.charge-on-card-input {
  width: 80%;
  margin: 50px auto;
  font-weight: 500;
  font-size: large;
}
.charge-on-card-input input {
  text-align: center;
}
.smr-error-message-block {
  background-color: var(--ion-color-danger);
  color: white;
  padding: 10px 10px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}
.payment-image-size {
  width: 200px;
  display: block;
  margin: 40px auto;
}
.payment-title {
  max-width: 600px;
  margin: 40px auto;
}
.reader-serial-number {
  color: #777;
  font-size: 10px;
}
.payment-process-button {
  margin: 20px auto 25px auto;
  width: 250px;
}
.cardReaderImage {
  width: 20px;
  display: inline-block;
}
.cardReaderSpan {
  margin-left: 5px;
  vertical-align: middle;
  font-weight: bold;
}
.connectedTerminalBlock {
  padding: 15px 25px;
  box-shadow: 0 2px 10px 0 #9a9a9a;
}
.readerP400lBlock {
  width: 80%;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.mini-loading {
  height: 30px;
  margin-top: -16px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-left-10 {
  margin-left: 10px;
}
.height-200 {
  height: 200px;
}
.padding-top-15 {
  padding-top: 15px;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
// @media (prefers-color-scheme: dark) {
//   .ios ion-input{
//     border: solid 1px #525252;
//     background: var(--ion-color-background);
//   }

//   .ios ion-select{
//     background: var(--ion-color-background);
//   }

//   .md ion-input{
//     border: solid 1px #525252;
//     background: var(--ion-color-background);
//   }

//   .md ion-select{
//     background: var(--ion-color-background);
//   }
// }

div.intercom-launcher {
  @media (max-width: 576px) {
    bottom: 60px !important;
  }
  bottom: 80px !important;
}

.owl-dt-container-inner {
  font-size: 1rem !important;
  padding: 1rem !important;
}
.owl-dt-container-row {
  height: unset !important;
}

.owl-dt-container {
  width: 15em;
}

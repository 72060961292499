owl-date-time-timer,
.owl-dt-container-control-button,
.owl-dt-calendar-control,
.owl-dt-container {
  font-family: 'roboto', sans-serif;
}
.owl-dt-container {
  font-size: 1.5rem;
}
.owl-dt-timer-content .owl-dt-timer-input {
  border-color:  var(--ion-color-medium);
}
.owl-dt-container-control-button {
  padding: 0.5rem;
}
.owl-dt-container-buttons {
  height: 50px;
  color: var(--ion-color-primary);
}
.owl-dt-timer-hour12 {
  color: var(--ion-color-primary);
}
.owl-dt-timer-hour12:hover span {
  background-color: var(--ion-color-primary) !important;
  color:  var(--ion-color-light-fff);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: var(--ion-color-primary) !important;
  color:  var(--ion-color-light-fff);
}
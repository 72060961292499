// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-background-color: #ffffff;

  --ion-color-primary: #2b4e49;
  --ion-color-primary-rgb: 43, 78, 73;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #264540;
  --ion-color-primary-tint: #40605b;

  --ion-color-secondary: #b0694d;
  --ion-color-secondary-rgb: 176, 105, 77;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #9b5c44;
  --ion-color-secondary-tint: #b8785f;

  --ion-color-tertiary: #5D7889;
  --ion-color-tertiary-rgb: 191,113,58;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #a86333;
  --ion-color-tertiary-tint: #c57f4e;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-danger-light: #ffcccc;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #ccc;
  --ion-color-medium-rgb: 204, 204, 204;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #b4b4b4;
  --ion-color-medium-tint: #d1d1d1;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-light-fff: #fff;
  --ion-color-light-fff-rgb: 255, 255, 255;
  --ion-color-light-fff-contrast: #000000;
  --ion-color-light-fff-contrast-rgb: 0, 0, 0;
  --ion-color-light-fff-shade: #e0e0e0;
  --ion-color-light-fff-tint: #ffffff;

  --ion-color-light-eee: #eee;
  --ion-color-light-eee-rgb: 238, 238, 238;
  --ion-color-light-eee-contrast: #000000;
  --ion-color-light-eee-contrast-rgb: 0, 0, 0;
  --ion-color-light-eee-shade: #d1d1d1;
  --ion-color-light-eee-tint: #f0f0f0;

  --ion-color-light-ddd: #ddd;
  --ion-color-light-ddd-rgb: 221, 221, 221;
  --ion-color-light-ddd-contrast: #000000;
  --ion-color-light-ddd-contrast-rgb: 0, 0, 0;
  --ion-color-light-ddd-shade: #c2c2c2;
  --ion-color-light-ddd-tint: #e0e0e0;

  --ion-color-hover: #ddd;
  --ion-color-hover-rgb: 221, 221, 221;
  --ion-color-hover-contrast: #000000;
  --ion-color-hover-contrast-rgb: 0, 0, 0;
  --ion-color-hover-shade: #c2c2c2;
  --ion-color-hover-tint: #e0e0e0;

  --ion-color-number: #3f4e5e;
  --ion-color-number-rgb: 63, 78, 94;
  --ion-color-number-contrast: #ffffff;
  --ion-color-number-contrast-rgb: 255, 255, 255;
  --ion-color-number-shade: #374553;
  --ion-color-number-tint: #52606e;

  --ion-color-darkgrey: #444;
  --ion-color-darkgrey-rgb: 68, 68, 68;
  --ion-color-darkgrey-contrast: #ffffff;
  --ion-color-darkgrey-contrast-rgb: 255, 255, 255;
  --ion-color-darkgrey-shade: #3c3c3c;
  --ion-color-darkgrey-tint: #575757;

  --ion-color-darkgrey-333: #333;
  --ion-color-darkgrey-333-rgb: 51, 51, 51;
  --ion-color-darkgrey-333-contrast: #ffffff;
  --ion-color-darkgrey-333-contrast-rgb: 255, 255, 255;
  --ion-color-darkgrey-333-shade: #2d2d2d;
  --ion-color-darkgrey-333-tint: #474747;


  --ion-color-tertiary-EFE: #EFEEEE;
  --ion-color-tertiary-EFE-rgb: 239,238,238;
  --ion-color-tertiary-EFE-contrast: #000000;
  --ion-color-tertiary-EFE-contrast-rgb: 0,0,0;
  --ion-color-tertiary-EFE-shade: #d2d1d1;
  --ion-color-tertiary-EFE-tint: #f1f0f0;
}

.ios body {
  --ion-background-color: #ffffff;
}
// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #5d7889;
//     --ion-color-primary-rgb: 93, 120, 137;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #526a79;
//     --ion-color-primary-tint: #6d8695;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80, 200, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106, 100, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47, 223, 117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255, 213, 52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255, 73, 97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244, 245, 248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0, 0, 0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #333;
//     --ion-color-medium-rgb: 51, 51, 51;
//     --ion-color-medium-contrast: #ffffff;
//     --ion-color-medium-contrast-rgb: 255, 255, 255;
//     --ion-color-medium-shade: #2d2d2d;
//     --ion-color-medium-tint: #474747;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34, 36, 40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255, 255, 255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;

//     --ion-color-light-fff: #000;
//     --ion-color-light-fff-rgb: 255, 255, 255;
//     --ion-color-light-fff-contrast: #ffffff;
//     --ion-color-light-fff-contrast-rgb: 0, 0, 0;
//     --ion-color-light-fff-shade: #000000;
//     --ion-color-light-fff-tint: #000;

//     --ion-color-light-eee: #404040;
//     --ion-color-light-eee-rgb: 64,64,64;
//     --ion-color-light-eee-contrast: #ffffff;
//     --ion-color-light-eee-contrast-rgb: 0, 0, 0;
//     --ion-color-light-eee-shade: #b8b8b8;
//     --ion-color-light-eee-tint: #d6d6d6;

//     --ion-color-light-ddd: #696969;
//     --ion-color-light-ddd-rgb: 105,105,105;
//     --ion-color-light-ddd-contrast: #ffffff;
//     --ion-color-light-ddd-contrast-rgb: 255,255,255;
//     --ion-color-light-ddd-shade: #5c5c5c;
//     --ion-color-light-ddd-tint: #787878;

//     --ion-color-hover: #4f4f4f;
//     --ion-color-hover-rgb: 79,79,79;
//     --ion-color-hover-contrast: #ffffff;
//     --ion-color-hover-contrast-rgb: 255,255,255;
//     --ion-color-hover-shade: #464646;
//     --ion-color-hover-tint: #616161;

//     --ion-color-number: #5c5d5e;
//     --ion-color-number-rgb: 92, 93, 94;
//     --ion-color-number-contrast: #ffffff;
//     --ion-color-number-contrast-rgb: 255, 255, 255;
//     --ion-color-number-shade: #515253;
//     --ion-color-number-tint: #6c6d6e;

//     --ion-color-darkgrey: #000;
//     --ion-color-darkgrey-rgb: 0, 0, 0;
//     --ion-color-darkgrey-contrast: #ffffff;
//     --ion-color-darkgrey-contrast-rgb: 255, 255, 255;
//     --ion-color-darkgrey-shade: #000000;
//     --ion-color-darkgrey-tint: #1a1a1a;

//     --ion-color-darkgrey-333: #000;
//     --ion-color-darkgrey-333-rgb: 0, 0, 0;
//     --ion-color-darkgrey-333-contrast: #ffffff;
//     --ion-color-darkgrey-333-contrast-rgb: 255, 255, 255;
//     --ion-color-darkgrey-333-shade: #000000;
//     --ion-color-darkgrey-333-tint: #1a1a1a;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }

$text-color: #000;
$background-color: #fff;
$sm-darkgrey: #444;
$sm-green: #325752;
$sm-red: #f53d3d;
$sm-requested: rgb(254, 255, 186);
$sm-unconfirmed: rgb(191, 239, 255);
$sm-reminded: rgb(218, 253, 208);
$sm-confirmed: rgb(111, 180, 68);
$sm-arrived: rgb(255, 239, 253);
$sm-noshow: rgb(255, 176, 176);
$sm-paid: #aaa;
$sm-timeoff: rgb(221, 221, 221);
$colors: (
  primary: #325752,
  secondary: #32db64,
  danger: $sm-red,
  light: #f4f4f4,
  black: #222,
  dark: #222,
  favorite: #69bb7b,
  sm-primary: $sm-green,
  pale: #666,
  faint: #ccc,
);
